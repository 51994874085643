@import url('https://fonts.googleapis.com/css?family=Open+Sans');

#inner {
  padding: 10px;
  /* padding-bottom:13rem;   Height of the footer */
  flex: 1;
}

.body {
  margin: 0;
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fcfcfc;
  padding: 0;
  height: 100%;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.table thead th {
  height: 6rem;
  font-family: 'Open Sans';
}

.table tbody td {
  font-family: 'Open Sans';
}

.card-header-custom {
  background-color: #8D6E63;
  color: #ffffff;
  padding: .75rem 1.25rem;
  margin-bottom: 0;
  border-bottom: 1px solid rgba(0, 0, 0, .125);
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
}

.card {
  /* width: 500px;
  height: 325px; */
  background: #F4F6FB;
  border: 1px solid white;
  box-shadow: 10px 10px 64px 0px rgba(180, 180, 207, 0.75);
  -webkit-box-shadow: 10px 10px 64px 0px rgba(186, 186, 202, 0.75);
  -moz-box-shadow: 10px 10px 64px 0px rgba(208, 208, 231, 0.75);
  border-top-left-radius: 1.5rem !important;
  border-top-right-radius: 1.5rem !important;
  margin: auto;
}

.cardProductMobile{
  background: #ffffff;
  border: 1px solid rgb(0, 0, 0);
  margin: auto;
  width: 100%;
  /* border-radius: 1.5rem !important; */
}

.cardProductPC{
  background: #ffffff;
  border: 1px solid rgb(0, 0, 0);
  margin: auto;
  width: 90%;
  /* border-radius: 1.5rem !important; */
}

.card-login {
/* .MuiCard-root{ */
  max-width: 500px;
  /* height: 325px; */
  background: #F4F6FB;
  border: 1px solid white;
  box-shadow: 10px 10px 64px 0px rgba(180, 180, 207, 0.75);
  -webkit-box-shadow: 10px 10px 64px 0px rgba(186, 186, 202, 0.75);
  -moz-box-shadow: 10px 10px 64px 0px rgba(208, 208, 231, 0.75);
  border-radius: 1.5rem !important;
  margin: auto;
}

.card-register {
  max-width: 1000px;
  /* height: 325px; */
  background: #F4F6FB;
  border: 1px solid white;
  box-shadow: 10px 10px 64px 0px rgba(180, 180, 207, 0.75);
  -webkit-box-shadow: 10px 10px 64px 0px rgba(186, 186, 202, 0.75);
  -moz-box-shadow: 10px 10px 64px 0px rgba(208, 208, 231, 0.75);
  border-radius: 1.5rem !important;
  margin: auto;
}

.form-label-register {
  display: flex;
  justify-content: center;
  align-items: center;
}

.collapsible .content {
  padding: 6px;
  background-color: #eeeeee;
}

.collapsible .header {
  background-color: #dddddd;
  padding: 6px;
  cursor: pointer;
}

.btn-primary {
  background: #6D4D42 !important;
}

.notVisibleProduct {
  background-color: #ff4000;
  opacity: 0.2;
}

.uploadData {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color:rgba(0, 0, 0, .75);
  z-index: 1400;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.noLanguageProduct {
  position: absolute;
  /* top: 0; */
  right: 0;
  /* bottom: 0; */
  left: 0;
  margin: auto;
  background-color:rgba(0, 0, 0, .75);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 1000px;
  height: 130px;
}

.noLanguageProductMovil {
  position: absolute;
  /* top: 0; */
  right: 0;
  /* bottom: 0; */
  left: 0;
  margin: auto;
  background-color:rgba(0, 0, 0, .75);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 1000px;
  height: 84px;
}

.noLanguageCategory {
  position: absolute;
  /* top: 0; */
  right: 0;
  /* bottom: 0; */
  left: 0;
  margin: auto;
  background-color:rgba(0, 0, 0, .75);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 1000px;
  /* height: 130px; */
}

.noLanguageCategoryMovil {
  position: absolute;
  /* top: 0; */
  right: 0;
  /* bottom: 0; */
  left: 0;
  margin: auto;
  background-color:rgba(0, 0, 0, .75);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 700px;
  height: 72px;
}

.noLanguageMenu {
  position: absolute;
  /* top: 0; */
  right: 0;
  /* bottom: 0; */
  left: 0;
  margin: auto;
  background-color:rgba(0, 0, 0, .75);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 561px;
  height: 390px;
}

.letterMayus p{
  text-transform: lowercase;
}

.letterMayus p::first-letter{
  text-transform: uppercase;
}