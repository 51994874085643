.divider
{
  position: relative;
  margin-top: 2%;
  margin-bottom: 2%;
  height: 1px;
}

.div-transparent:before
{
  content: "";
  position: absolute;
  top: 0;
  left: 5%;
  right: 5%;
  width: 90%;
  height: 1px;
  background-image: linear-gradient(to right, transparent, rgb(48,49,51), transparent);
}
