/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

  
    .padding-footer{
        padding-top: 24px;
    }
  
  .container-footer {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto; }
    @media (min-width: 576px) {
      .container-footer {
        max-width: 540px; } }
    @media (min-width: 768px) {
      .container-footer {
        max-width: 720px; } }
    @media (min-width: 992px) {
      .container-footer {
        max-width: 960px; } }
    @media (min-width: 1200px) {
      .container-footer {
        max-width: 1140px; } }
  
  .container-footer-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto; }
  
  .ftco-footer-social li {
    list-style: none;
    margin: 0 10px 0 0;
    display: inline-block; }
    .ftco-footer-social li a {
      height: 40px;
      width: 40px;
      display: block;
      background: rgba(0, 0, 0, 0.05);
      border-radius: 50%;
      position: relative; }
      .ftco-footer-social li a span {
        position: absolute;
        font-size: 20px;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%); }
      .ftco-footer-social li a:hover {
        color: #fff; }
  
  footer {
    padding: 7em 0; 
  }
  
  .footer-10 {
    background: #5D4038;
    padding: 0 0 4em 0; 
  }
    .footer-10 .border-top, .footer-10 .border-bottom {
      border-color: rgba(255, 255, 255, 0.1) !important; 
    }
    .footer-10 .footer-heading {
      font-size: 18px;
      color: #fff;
      margin-bottom: 30px; 
    }
    .footer-10 p {
      color: rgba(255, 255, 255, 0.3); 
    }
    .footer-10 a {
      color: #1089ff; 
    }
    .footer-10 .con.con-1 {
      background: #3E2622; 
    }
    .footer-10 .con.con-2 {
      background: #4D342F; 
    }
    .footer-10 .con.con-3 {
      background: #6D4D42; 
    }
    .footer-10 .list-unstyled li a {
      color: rgba(255, 255, 255, 0.2); 
    }
      .footer-10 .list-unstyled li a:hover {
        color: #fff; 
      }
    .footer-10 .con-info .icon {
      width: 50px;
      height: 50px;
      background: #AB7F43;
      border-radius: 50%;
      margin: 0 auto;
      margin-bottom: 10px; 
    }
      .footer-10 .con-info .icon span {
        color: #fff;
        font-size: 24px; 
      }
    .footer-10 .con-info span {
      color: rgba(255, 255, 255, 0.8); 
    }
    .footer-10 .subscribe-form .form-group {
      position: relative;
      margin-bottom: 0;
      border-radius: 0; 
    }
      .footer-10 .subscribe-form .form-group input {
        background: white !important;
        border: none !important;
        outline: none !important;
        color: rgba(0, 0, 0, 0.3) !important;
        font-size: 16px;
        border-radius: 0; 
      }
        .footer-10 .subscribe-form .form-group input::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: rgba(0, 0, 0, 0.3) !important; 
        }
        .footer-10 .subscribe-form .form-group input::-moz-placeholder {
          /* Firefox 19+ */
          color: rgba(0, 0, 0, 0.3) !important; 
        }
        .footer-10 .subscribe-form .form-group input:-ms-input-placeholder {
          /* IE 10+ */
          color: rgba(0, 0, 0, 0.3) !important; 
        }
        .footer-10 .subscribe-form .form-group input:-moz-placeholder {
          /* Firefox 18- */
          color: rgba(0, 0, 0, 0.3) !important; 
        }
        .footer-10 .subscribe-form .form-group input:focus {
          outline: none !important;
          -webkit-box-shadow: none;
          box-shadow: none; 
        }
      .footer-10 .subscribe-form .form-group .submit {
        color: #fff !important;
        display: block;
        width: 130px;
        height: 52px;
        font-size: 16px;
        background: #1089ff !important;
        border: none;
        border-radius: 0; 
      }
        .footer-10 .subscribe-form .form-group .submit:hover, .footer-10 .subscribe-form .form-group .submit:focus {
          text-decoration: none !important;
          outline: none !important; 
        }
    .footer-10 .subscribe-form .subheading {
      display: inline-block;
      margin-top: 5px;
      color: rgba(255, 255, 255, 0.3);
      font-size: 13px; 
    }
    .footer-10 .ftco-footer-social li a {
      background: rgba(255, 255, 255, 0.1);
      color: #fff; 
    }

      #clouds path {
        fill: #5D4038;
        stroke: #5D4038;
      }

      .related {
        background: #fff;
        color: #777;
        text-align: center;
        /* position: absolute;
        bottom: 0;
        width: 100%; */
      }
      
      .related a {
        font-weight: 700;
        color: #3498db;
        opacity: 0.7;
      }
      
      .related a:hover {
        opacity: 1;
      }